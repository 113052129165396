import { Colors, Types } from '@walter/shared'
import { Avatar, AvatarSize } from '@walter/shared-web'
import React from 'react'
import styled, { css } from 'styled-components'
import { square } from '../../styles/global'
import { Icon } from '../Icon'

const Container = styled.div<{ onClick?: (event: React.MouseEvent<HTMLDivElement>) => void }>`
  display: inline-flex;
  position: relative;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`

const Star = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -7px;
  right: -7px;
  background-color: ${Colors.yellow};
  border: 2px solid ${Colors.white};
  border-radius: 50%;
  color: ${Colors.white};
  ${square('22px')};
`

type ResidentProps = {
  size?: AvatarSize
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  name: string
  type: Types.RESIDENT_TYPE
  isOnline?: boolean
  photo?: string
}

export function Resident({ size = 'medium', name, type, onClick, photo, isOnline }: ResidentProps) {
  return (
    <Container data-test-id={`Resident_Container_${name}`} onClick={onClick}>
      <Avatar size={size} color={Colors.greyLight} name={name} isOnline={isOnline} photo={photo} />
      {type === 'boardMember' && (
        <Star>
          <Icon icon="star" size="tiny" />
        </Star>
      )}
    </Container>
  )
}
