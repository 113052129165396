import { Colors, Spacing, Types } from '@walter/shared'
import { t } from '../../../utils'
import { mix, rgba, stripUnit } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import {
  animationCurve,
  animationTime,
  borderRadius,
  boxShadow,
  inputHeight,
  inputHeightSmall,
} from '../../../styles/global'
import { fontSizes, fontWeights } from '../../../styles/typography'
import { ButtonTheme } from '../../Button'
import { ConfirmModal } from '../../ConfirmModal'

const ButtonElement = styled.button<{
  fullwidth?: boolean
  isSmall?: boolean
  theme?: ButtonTheme
  variant?: Types.Variant
  customColor?: string
  marginBottom?: string
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius};
  height: ${inputHeight};
  padding: 0 ${`${(stripUnit(Spacing.medium) as number) * 1.25}px`};
  cursor: pointer;
  text-align: center;
  transition: all ${animationTime} ${animationCurve};
  ${boxShadow};

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

  ${(props) =>
    props.fullwidth &&
    css`
      display: flex;
      width: 100%;
    `}

  ${(props) =>
    props.isSmall &&
    css`
      height: ${inputHeightSmall};
      padding: 0 ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
    `}
    
    ${(props) =>
    props.theme === 'link' &&
    css`
      border: 0;
      border-radius: 0;
      box-shadow: unset;
      height: ${inputHeightSmall};
      padding: 0 ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
    `}
  
    ${(props) =>
    props.theme === 'primary' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.primaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, Colors.primaryColor, Colors.black)};
      }
    `}
  
    ${(props) =>
    props.theme === 'primary' &&
    props.variant === 'outline' &&
    css`
      color: ${Colors.primaryColor};
      background-color: transparent;
      box-shadow: inset 0 0 0 2px ${Colors.primaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${Colors.primaryColor};
        color: ${Colors.white};
      }
    `}
  
    ${(props) =>
    props.theme === 'primary' &&
    props.variant === 'form' &&
    css`
      height: ${inputHeightSmall};
      padding: 0 ${Spacing.medium};
      border-radius: ${borderRadius};
    `}
  
    ${(props) =>
    props.theme === 'secondary' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.secondaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, Colors.secondaryColor, Colors.black)};
      }
    `}
  
    ${(props) =>
    props.theme === 'secondary' &&
    props.variant === 'outline' &&
    css`
      color: ${Colors.secondaryColor};
      background-color: transparent;
      box-shadow: inset 0 0 0 2px ${Colors.secondaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${Colors.secondaryColor};
        color: ${Colors.white};
      }
    `}
  
    ${(props) =>
    props.theme === 'tertiary' &&
    css`
      color: ${Colors.greyDark};
      background-color: ${Colors.white};
      border: 1px solid ${Colors.borderColor};

      &:hover,
      &:focus,
      &:active {
        border-color: ${mix(0.9, Colors.borderColor, Colors.black)};
      }
    `}
  
    ${(props) =>
    props.theme === 'tertiary' &&
    props.variant === 'form' &&
    css`
      border: 1px solid ${Colors.borderColorForm};
      height: ${inputHeightSmall};
      padding: 0 ${Spacing.medium};
      border-radius: ${borderRadius};

      &:hover,
      &:focus,
      &:active {
        border: 1px solid ${Colors.borderColor};
      }
    `}
  
    ${(props) =>
    props.theme === 'negative' &&
    css`
      color: ${Colors.red};
      background-color: ${mix(0.15, Colors.red, Colors.white)};
      box-shadow: none;

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.25, Colors.red, Colors.white)};
      }
    `}
  
    ${(props) =>
    props.theme === 'negative' &&
    props.variant === 'form' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.coral};
      box-shadow: none;
      height: ${inputHeightSmall};
      padding: 0 ${Spacing.medium};
      border-radius: ${borderRadius};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.25, Colors.coral, Colors.white)};
      }
    `}
  
    ${(props) =>
    props.theme === 'positive' &&
    css`
      color: ${Colors.green};
      background-color: ${mix(0.15, Colors.green, Colors.white)};
      box-shadow: none;

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.25, Colors.green, Colors.white)};
      }
    `}
      
    ${(props) =>
    props.theme === 'link' &&
    css`
      border: 0;
      border-radius: 0;
      box-shadow: unset;
      height: ${inputHeightSmall};
      padding: 0 ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
    `}
  
      ${(props) =>
    props.theme === 'custom' &&
    props.customColor &&
    css`
      color: ${props.customColor};
      background-color: ${rgba(props.customColor, 0.2)};
      box-shadow: none;

      &:hover,
      &:focus,
      &:active {
        background-color: ${rgba(props.customColor, 0.3)};
      }
    `}
  
    &[disabled] {
    cursor: not-allowed;
    /* pointer-events: none; */
    opacity: 0.4;
    user-select: none;
  }
`

const Label = styled.span<{ isLoading?: boolean; isSmall?: boolean }>`
  display: inline-flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.small};
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};

  ${(props) =>
    props.isSmall &&
    css`
      font-weight: ${fontWeights.medium};
    `};

  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0;
      visibility: hidden;
    `};
`

export function Button({
  type = 'button',
  text,
  onClick,
  isSmall = false,
  theme = 'tertiary',
  disabled = false,
  ...rest
}: {
  type?: 'button' | 'submit' | 'reset'
  text: string
  isSmall?: boolean
  disabled: boolean
  theme?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) {
  return (
    <ButtonElement isSmall={isSmall} type={type} theme={theme} onClick={onClick} disabled={disabled} {...rest}>
      <Label isLoading={false} isSmall={false}>
        {text}
      </Label>
    </ButtonElement>
  )
}

export function ButtonWithModal({
  dataTestId,
  type,
  text,
  delegateClick,
  theme = 'tertiary',
  modalText = t('button:confirm-modal-text'),
  ...rest
}: {
  dataTestId?: string
  type: 'button' | 'submit' | 'reset'
  text: string
  delegateClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  theme?: string
  modalText?: string
  [k: string]: any
}) {
  const [showConfimation, setShowConfirmation] = React.useState<boolean>(false)
  const handleClick = () => setShowConfirmation(true)

  return (
    <>
      <ButtonElement data-test-id={dataTestId} theme={theme} type={type} {...rest} onClick={handleClick}>
        <Label isLoading={false} isSmall={false}>
          {text}
        </Label>
      </ButtonElement>

      <ConfirmModal
        visible={showConfimation}
        onConfirm={delegateClick}
        onClose={() => setShowConfirmation(false)}
        customDescription={modalText}
      />
    </>
  )
}
