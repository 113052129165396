import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { typography, ErrorMessage } from '@walter/shared-web'
import { stripUnit } from 'polished'
import { animationCurve, animationTime, borderRadius, controlDimensions, square } from '../../../styles/global'
import { Icon } from '../../Icon'
import { useFormContext } from 'react-hook-form'

const Container = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  flex: 1; /* Take up space when inside flex container */

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.5; /* Overwrite iOS styles */
    `}
`

const Control = styled.div`
  flex-shrink: 0;
  display: flex;
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const Indicator = styled.div`
  ${square(controlDimensions)};
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: ${`${(stripUnit(borderRadius) as number) * 0.8}px`};
  flex-shrink: 0;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderColor};
  margin-top: 3px; /* Align text with checkbox */
  transition: background-color ${animationTime} ${animationCurve}, border-color ${animationTime} ${animationCurve};

  ${Input}:checked + & {
    background-color: ${Colors.secondaryColor};
    border-color: ${Colors.secondaryColor};
  }
`

const IconWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};
  display: flex;
  color: ${Colors.white};

  ${Input}:checked ~ ${Indicator} & {
    opacity: 1; /* Show icon when checkbox is checked */
    visibility: visible;
  }
`

const Label = styled.div<{ size: string }>`
  text-align: left;
  margin-left: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};

  ${(props) =>
    props.size === 'small' &&
    css`
      margin-left: ${Spacing.small};
      font-size: ${typography.fontSizes.small};
    `}
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const InlineHint = styled.span`
  color: ${Colors.greyLight};
  font-size: ${typography.fontSizes.small};
`

export type CheckboxProps = {
  dataTestId?: string
  label?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClick?: (checkboxValue: boolean) => void
  hint?: string
  inlineHint?: string
  name: string
  disabled?: boolean
  size?: string
  errorMessage: string
  [k: string]: any
  isChecked?: boolean
  custom?: boolean
}

export const Checkbox = ({
  dataTestId,
  name,
  label,
  hint,
  inlineHint,
  disabled = false,
  size = 'default',
  errorMessage,
  handleClick,
  isChecked,
  custom,
  ...rest
}: CheckboxProps) => {
  const { register, setValue } = useFormContext()

  return (
    <Container data-cy={`checkbox-input-${name || label || 'generic'}`} disabled={disabled}>
      <Control data-test-id="Checkbox_Control">
        {handleClick ? (
          <Input
            data-test-id={dataTestId}
            defaultChecked={isChecked}
            onClick={(e: any) => {
              setValue(name, handleClick(e.target.checked), { shouldDirty: true })
            }}
            name={name}
            readOnly
          />
        ) : custom ? (
          <Input onChange={rest.onChange} name={name} defaultChecked={isChecked} data-test-id={dataTestId} />
        ) : (
          <Input {...register(name)} {...rest} readOnly data-test-id={dataTestId} />
        )}
        <Indicator>
          <IconWrap>
            <Icon icon="checkmark" size="tiny" />
          </IconWrap>
        </Indicator>
      </Control>
      {label && (
        <Label size={size}>
          <>
            {label}
            {inlineHint && (
              <>
                <br />
                <InlineHint>{inlineHint}</InlineHint>
              </>
            )}
          </>
          {hint && <Hint data-tip={hint}>(?)</Hint>}
        </Label>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}
