import { Colors, Spacing, Types } from '@walter/shared'
import { mix, rgba, stripUnit } from 'polished'
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import {
  animationCurve,
  animationTime,
  borderRadius,
  boxShadow,
  inputHeight,
  inputHeightSmall,
} from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'
import { ConfirmModal } from '../ConfirmModal'
import { Icon } from '../Icon'
import { Loading } from '../Loading'

const Container = styled.button<{
  fullwidth?: boolean
  size?: ButtonSize
  theme?: ButtonTheme
  variant?: Types.Variant
  customColor?: string
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius};
  height: ${inputHeight};
  padding: 0 ${`${(stripUnit(Spacing.medium) as number) * 1.25}px`};
  cursor: pointer;
  text-align: center;
  transition: all ${animationTime} ${animationCurve};
  ${boxShadow};

  ${(props) =>
    props.fullwidth &&
    css`
      display: flex;
      width: 100%;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      height: ${inputHeightSmall};
      padding: 0 ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
    `}
  
  ${(props) =>
    props.theme === 'link' &&
    css`
      border: 0;
      border-radius: 0;
      box-shadow: unset;
      height: ${inputHeightSmall};
      padding: 0 ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
    `}

  ${(props) =>
    props.theme === 'primary' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.primaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, Colors.primaryColor, Colors.black)};
      }
    `}

  ${(props) =>
    props.theme === 'primary' &&
    props.variant === 'outline' &&
    css`
      color: ${Colors.primaryColor};
      background-color: transparent;
      box-shadow: inset 0 0 0 2px ${Colors.primaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${Colors.primaryColor};
        color: ${Colors.white};
      }
    `}

  ${(props) =>
    props.theme === 'primary' &&
    props.variant === 'form' &&
    css`
      height: ${inputHeightSmall};
      padding: 0 ${Spacing.medium};
      border-radius: ${borderRadius};
    `}

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.secondaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, Colors.secondaryColor, Colors.black)};
      }
    `}

  ${(props) =>
    props.theme === 'secondary' &&
    props.variant === 'outline' &&
    css`
      color: ${Colors.secondaryColor};
      background-color: transparent;
      box-shadow: inset 0 0 0 2px ${Colors.secondaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${Colors.secondaryColor};
        color: ${Colors.white};
      }
    `}

  ${(props) =>
    props.theme === 'tertiary' &&
    css`
      color: ${Colors.greyDark};
      background-color: ${Colors.white};
      border: 1px solid ${Colors.borderColor};

      &:hover,
      &:focus,
      &:active {
        border-color: ${mix(0.9, Colors.borderColor, Colors.black)};
      }
    `}

  ${(props) =>
    props.theme === 'tertiary' &&
    props.variant === 'form' &&
    css`
      border: 1px solid ${Colors.borderColorForm};
      height: ${inputHeightSmall};
      padding: 0 ${Spacing.medium};
      border-radius: ${borderRadius};

      &:hover,
      &:focus,
      &:active {
        border: 1px solid ${Colors.borderColor};
      }
    `}

  ${(props) =>
    props.theme === 'negative' &&
    css`
      color: ${Colors.red};
      background-color: ${mix(0.15, Colors.red, Colors.white)};
      box-shadow: none;

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.25, Colors.red, Colors.white)};
      }
    `}

  ${(props) =>
    props.theme === 'negative' &&
    props.variant === 'form' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.coral};
      box-shadow: none;
      height: ${inputHeightSmall};
      padding: 0 ${Spacing.medium};
      border-radius: ${borderRadius};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.25, Colors.coral, Colors.white)};
      }
    `}

  ${(props) =>
    props.theme === 'positive' &&
    css`
      color: ${Colors.green};
      background-color: ${mix(0.15, Colors.green, Colors.white)};
      box-shadow: none;

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.25, Colors.green, Colors.white)};
      }
    `}
    
  ${(props) =>
    props.theme === 'link' &&
    css`
      border: 0;
      border-radius: 0;
      box-shadow: unset;
      height: ${inputHeightSmall};
      padding: 0 ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
    `}

    ${(props) =>
    props.theme === 'custom' &&
    props.customColor &&
    css`
      color: ${props.customColor};
      background-color: ${rgba(props.customColor, 0.2)};
      box-shadow: none;

      &:hover,
      &:focus,
      &:active {
        background-color: ${rgba(props.customColor, 0.3)};
      }
    `}

  &[disabled] {
    cursor: not-allowed;
    /* pointer-events: none; */
    opacity: 0.4;
    user-select: none;
  }
`

const Label = styled.span<{ isLoading?: boolean; size?: ButtonSize }>`
  display: inline-flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.small};
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};

  ${(props) =>
    props.size === 'small' &&
    css`
      font-weight: ${fontWeights.medium};
    `};

  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0;
      visibility: hidden;
    `};
`
const IconWrap = styled.div<{ size?: ButtonSize }>`
  display: flex;
  margin-right: ${(props) => (props.size === 'small' ? Spacing.tiny : Spacing.small)};
`

export type ButtonSize = 'tiny' | 'small'

export type ButtonTheme =
  | 'primary'
  | 'outline'
  | 'secondary'
  | 'tertiary'
  | 'negative'
  | 'positive'
  | 'link'
  | 'custom'
  | 'transparent'

export type ButtonProps = {
  dataTestId?: string
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any
  size?: ButtonSize
  theme?: ButtonTheme
  customColor?: string
  variant?: Types.Variant
  icon?: Types.IconName
  fullwidth?: boolean
  type?: 'button' | 'submit' | 'reset'
  isLoading?: any
  withConfirmation?: boolean
  disabled?: boolean
  id?: string
  testID?: string
  hint?: string
  customDescription?: string
  className?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      theme = 'tertiary',
      variant,
      icon,
      customColor,
      size,
      isLoading,
      fullwidth,
      type = 'button',
      withConfirmation,
      onClick,
      testID,
      dataTestId,
      hint,
      customDescription,
      disabled,
      className,
    }: ButtonProps,
    ref,
  ) => {
    const [isDoingAsyncAction, setIsDoingAsyncAction] = React.useState(false)
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = React.useState(false)

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (withConfirmation && !confirmationModalIsOpen) {
        setConfirmationModalIsOpen(true)
      } else if (onClick) {
        const res = onClick(event)
        // @ts-ignore

        if (res?.then) {
          setIsDoingAsyncAction(true)
          try {
            await res
            // eslint-disable-next-line no-empty
          } catch {}
          setIsDoingAsyncAction(false)
          setConfirmationModalIsOpen(false)
        } else {
          setConfirmationModalIsOpen(false)
        }
      }
    }

    const isDisabled = disabled || isLoading || isDoingAsyncAction

    return (
      <>
        <Container
          data-test-id={dataTestId ?? ''}
          data-testid={testID ?? ''}
          data-cy={testID ?? ''}
          fullwidth={fullwidth}
          type={type}
          theme={theme}
          variant={variant}
          size={size}
          customColor={customColor}
          disabled={isDisabled}
          ref={ref}
          onClick={isDisabled ? undefined : handleClick}
          data-tip={hint}
          className={className}
        >
          {(isLoading || isDoingAsyncAction) && (
            <Loading
              size={size === 'small' ? 'small' : undefined}
              color={theme === 'primary' ? Colors.white : theme === 'negative' ? Colors.red : Colors.primaryColor}
              type="absoluteCenter"
            />
          )}
          <Label isLoading={isLoading || isDoingAsyncAction} size={size}>
            {icon && (
              <IconWrap size={size}>
                <Icon icon={icon} size="small" />
              </IconWrap>
            )}
            {children}
          </Label>
        </Container>

        {withConfirmation && (
          <ConfirmModal
            visible={confirmationModalIsOpen}
            onConfirm={handleClick}
            onClose={() => setConfirmationModalIsOpen(false)}
            isLoading={isDoingAsyncAction || isLoading}
            customDescription={customDescription}
          />
        )}
      </>
    )
  },
)
