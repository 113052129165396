import { Api, SharedUtils } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

export const ManagerProfilei18nFields = {
  firstName: t('user:first-name'),
  lastName: t('user:last-name'),
  phone: t('user:mobile-phone-number'),
  officePhone: t('user:office-phone-number'),
  title: t('user:title'),
  preferedLanguage: t('user:preferred-language'),
  email: t('user:email'),
  password: t('change-your-password-here'),
  isAvailableForTasks: t('availability'),
}

export const ManagerProfileSchema = z.object({
  firstName: z
    .string({ required_error: i18n.t('input:is-required', { field: ManagerProfilei18nFields.title }) })
    .min(1, { message: i18n.t('input:is-required', { field: ManagerProfilei18nFields.title }) })
    .max(20, fieldIsTooLong(20, ManagerProfilei18nFields.firstName)),
  lastName: z.string().optional().nullable(),
  phone: z
    .object({
      id: z.string().optional().nullable(),
      number: z
        .string()
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input:must-be-phone-number', {
            field: ManagerProfilei18nFields.phone,
            formats: '+11231231234, 1231231234',
          }),
        })
        .or(z.literal('').nullable().optional()),
    })
    .optional()
    .nullable(),
  officePhone: z
    .object({
      number: z
        .string()
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input:must-be-phone-number', {
            field: ManagerProfilei18nFields.officePhone,
            formats: '+11231231234, 1231231234',
          }),
        })
        .or(z.literal('').nullable().optional()),
    })
    .optional()
    .nullable(),
  title: z.string().optional().nullable(),
  preferedLanguage: z.string().optional(),
  email: z
    .string({
      invalid_type_error: i18n.t('input:is-required', { field: i18n.t('email') }),
    })
    .min(1, { message: i18n.t('input:is-required', { field: i18n.t('email') }) })
    .email({ message: t('invalid-email') }),
  password: z
    .string()
    .refine((value) => value.length === 0 || value.length >= 8, {
      message: t('create-strong-password'),
    })
    .optional(),
  isAvailableForTasks: z
    .enum(['true', 'false'])
    .transform((value) => value === 'true')
    .optional()
    .nullable(),
})

export type ManagerProfile = z.infer<typeof ManagerProfileSchema> & { status: Api.Ad_Status }
