import { z } from 'zod'
import { i18n, t } from '../../utils'
import { SharedUtils } from '@walter/shared'

export const OtherResidentsi18nFields = {
  firstName: t('first-name'),
  lastName: t('last-name'),
  email: t('email'),
  phone: t('mobile-phone-number'),
  type: t('resident-type'),
}

export const OtherResidentsSchema = z.object({
  firstName: z
    .string({ required_error: i18n.t('input:is-required', { field: OtherResidentsi18nFields.firstName }) })
    .min(1, { message: i18n.t('input:is-required', { field: OtherResidentsi18nFields.firstName }) }),
  lastName: z.string().optional().nullable(),
  email: z
    .string({ required_error: i18n.t('input:is-required', { field: OtherResidentsi18nFields.email }) })
    .min(1, i18n.t('input:is-required', { field: OtherResidentsi18nFields.email }))
    .email(t('enter-email-invalid')),
  phone: z
    .object({
      number: z
        .string()
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('enter-phone-number-invalid'),
        })
        .or(z.literal('').nullable().optional()),
    })
    .optional()
    .nullable(),
  type: z
    .string({
      required_error: i18n.t('input:is-required', { field: OtherResidentsi18nFields.type }),
      invalid_type_error: i18n.t('input:is-required', { field: OtherResidentsi18nFields.type }),
    })
    .min(1, i18n.t('input:is-required', { field: OtherResidentsi18nFields.type })),
})

export const OtherResidentsUpdateSchema = OtherResidentsSchema.extend({ id: z.string() })

export type OtherResidents = z.infer<typeof OtherResidentsSchema>
