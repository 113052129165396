import { Colors, Spacing } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import styled from 'styled-components'
import { fontSizes } from '../../styles/typography'
import { animationCurve, animationTime, borderRadius } from '../../styles/global'
import React from 'react'
import { Icon } from '../Icon'

export function FilterToolbar(props: {
  dataTestId?: string
  components: React.ReactNode
  appliedFilters: {
    id: string
    label?: string
  }[]
  remove: (appliedFilter: { id: string; label?: string }) => void
}) {
  const { dataTestId, components, appliedFilters, remove } = props
  return (
    <Toolbar data-test-id={`${dataTestId}_FilterBar_Container`}>
      {components}
      {appliedFilters.length > 0 && (
        <FilterTagsContainer>
          {appliedFilters.map((selection, i) => (
            <TagContainer key={`${selection.id}-${i}`} data-test-id={'FilterTag_' + selection.label}>
              {selection.label}
              <TagRemove data-test-id="Close_Button" onClick={() => remove(selection)}>
                <Icon icon="close" size="tiny" />
              </TagRemove>
            </TagContainer>
          ))}
        </FilterTagsContainer>
      )}
    </Toolbar>
  )
}

const Toolbar = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: ${Spacing.small};
`

const FilterTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Spacing.small};
  gap: ${Spacing.tiny};
`

const TagContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${Spacing.small};
  margin-bottom: ${Spacing.small};
  background-color: ${rgba(Colors.grey, 0.15)};
  font-size: ${fontSizes.small};
  border-radius: ${`${(stripUnit(borderRadius) as number) * 0.6}px`};
  padding: ${Spacing.tiny} ${Spacing.small};
`

const TagRemove = styled.button`
  display: flex;
  margin-left: ${Spacing.tiny};
  transition: opacity ${animationTime} ${animationCurve};
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`
