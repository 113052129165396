import { Api, SharedUtils } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

export const ResidentProfilei18nFields = {
  firstName: t('user:first-name'),
  lastName: t('user:last-name'),
  phone: t('user:mobile-phone-number'),
  title: t('user:title'),
  preferedLanguage: t('user:preferred-language'),
  email: t('user:email'),
  password: t('change-your-password-here'),
}

export const residentProfileSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: i18n.t('input:is-required', { field: ResidentProfilei18nFields.firstName }) })
    .max(20, { message: fieldIsTooLong(20, ResidentProfilei18nFields.firstName) }),
  lastName: z.string().optional().nullable(),
  phone: z
    .object({
      __typename: z.string().optional().nullable(),
      id: z.string().optional().nullable(),
      number: z
        .string()
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input:must-be-phone-number', {
            field: ResidentProfilei18nFields.phone,
            formats: '+11231231234, 1231231234',
          }),
        })
        .or(z.literal('').nullable().optional()),
    })
    .optional()
    .nullable(),
  title: z.string().optional().nullable(),
  preferedLanguage: z.string().optional(),
  email: z
    .string()
    .email(t('enter-email-invalid'))
    .min(1, i18n.t('input:is-required', { field: ResidentProfilei18nFields.email })),
  password: z.string().optional(),
})

export const profileSchemaUpdate = residentProfileSchema.extend({ id: z.string() })

export type ResidentProfile = z.infer<typeof residentProfileSchema> & { status: Api.Ad_Status }
export type ResidentProfileUpdate = z.infer<typeof profileSchemaUpdate> & { status: Api.Ad_Status }
