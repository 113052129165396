import { Colors } from '@walter/shared'
import { GroupBase, StylesConfig } from 'react-select'
import { Option } from '../types'

export function setMultiSelectedColor(color: keyof typeof Colors): StylesConfig<Option, boolean, GroupBase<Option>> {
  const selectedColor = Colors[color]
  return {
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: `${selectedColor} !important`,
      }
    },
    multiValueRemove: (styles) => {
      return {
        ...styles,
        color: `${selectedColor}90 !important`,
        ':hover': {
          color: `${selectedColor} !important`,
        },
      }
    },
    multiValue: (styles) => {
      return {
        ...styles,
        background: `${selectedColor}30 !important`,
        color: `${selectedColor} !important`,
      }
    },
  }
}
