import { Spacing } from '@walter/shared'
import { stripUnit } from 'polished'
import React from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import { t } from '../../utils'
import { Label } from '../Label'

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

export type SelectProps = {
  id?: string
  dataTestId?: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  options?: { [key: string]: any }[]
  isMulti?: boolean
  value?: any
  onChange?: (option: any) => void
  className?: string
  name?: string
  testID?: string
  path?: string
  minWidth?: number
  hint?: string
  stopPropagation?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const Select = ({ label, minWidth, hint, stopPropagation, onClick, ...rest }: SelectProps) => {
  return (
    <div data-cy={rest.testID}>
      {!!label && (
        <Label style={minWidth ? { minWidth: minWidth } : {}}>
          {label}
          {hint && <Hint data-tip={hint}>(?)</Hint>}
        </Label>
      )}
      <div
        data-cy={rest.path || 'wrap-react-select'}
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation()
          }
          if (onClick) {
            onClick(e)
          }
        }}
      >
        <ReactSelect
          id={rest?.dataTestId} // For automation testing. Instead of data-test-id because we don't want to edit an external component
          classNamePrefix="select"
          {...rest}
          placeholder={(rest?.placeholder?.length ?? 0) > 0 ? rest.placeholder : `${t('select')}...`}
        />
      </div>
    </div>
  )
}
