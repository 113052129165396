import { Api, SharedUtils } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

// eslint-disable-next-line no-irregular-whitespace
// Space character used by Intl.NumberFormat. Ex: 10 000,00
const NARROW_NO_BREAK_SPACE_CHAR = String.fromCharCode(8239)

export const Adi18nFields = {
  title: t('fields-ad:title'),
  description: t('fields-ad:description'),
  price: t('fields-ad:price'),
  images: t('fields-ad:photos'),
  owner: t('fields-ad:owner'),
  createAt: t('fields-ad:posted-on'),
  phoneNumber: t('marketplace:mobile-phone-number'),
  email: t('marketplace:email'),
}

export const adSchema = z.object({
  title: z
    .string({ required_error: i18n.t('input:is-required', { field: Adi18nFields.title }) })
    .min(1, { message: i18n.t('input:is-required', { field: Adi18nFields.title }) })
    .max(200, fieldIsTooLong(200, Adi18nFields.title)),
  description: z.string().max(200, fieldIsTooLong(200, Adi18nFields.description)).optional(),
  price: z
    .number()
    .or(
      z
        .string()
        .transform((val) => {
          const value = val?.replace(',', '.').replaceAll(NARROW_NO_BREAK_SPACE_CHAR, '')
          return Number(value)
        })
        .refine(
          (val) => {
            return !isNaN(val) && val < 9_999_999
          },
          { message: i18n.t('input:must-be-number', { field: Adi18nFields.price }) },
        ),
    )
    .optional(),
  phone: z
    .object({
      number: z
        .string()
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input:must-be-phone-number', {
            field: Adi18nFields.phoneNumber,
            formats: '+11231231234, 1231231234',
          }),
        })
        .or(z.literal('').nullable().optional()),
    })
    .optional()
    .nullable(),
  email: z
    .string()
    .email({ message: t('invalid-email') })
    .or(z.literal('').optional()),
  status: z.nativeEnum(Api.Ad_Status),
  isSold: z.boolean().nullable().default(false),
  owner: z
    .object({
      id: z
        .string({
          required_error: i18n.t('input:is-required', { field: Adi18nFields.owner }),
          invalid_type_error: i18n.t('input:is-required', { field: Adi18nFields.owner }),
        })
        .min(1, { message: i18n.t('input:is-required', { field: Adi18nFields.owner }) }),
    })
    .nullable()
    .optional(),
  images: z.any().optional().nullable(),
})

export const adSchemaUpdate = adSchema.extend({ id: z.string() })

export type Ad = z.infer<typeof adSchema> & { status: Api.Ad_Status }
export type AdUpdate = z.infer<typeof adSchemaUpdate> & { status: Api.Ad_Status }
