import { SharedUtils } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'
import isInteger from 'lodash/isInteger'

export const ManagingCompanyi18nFields = {
  shortName: t('managing-company-name-short'),
  longName: t('managing-company-name-long'),
  email: t('email'),
  phoneNumber: t('phone-number'),
  emergencyPhone: t('emergency-phone-number'),
  extension: t('extension'),
  emergencyExtension: t('extension'),
  logo: t('logo'),
  logoAvatar: t('logo-avatar'),
  address: t('address-line-1'),
  address2: t('address-line-2'),
  apartmentNumber: t('unit-number'),
  city: t('city'),
  zip: t('postal-code'),
  state: t('province-state'),
  country: t('country'),
}

export const managingCompanySchema = z.object({
  shortName: z.string().optional().nullable(),
  longName: z.string().optional().nullable(),
  email: z
    .string({
      invalid_type_error: i18n.t('input:is-required', { field: i18n.t('email') }),
    })
    .min(1, { message: i18n.t('input:is-required', { field: i18n.t('email') }) })
    .email({ message: t('invalid-email') }),
  phone: z
    .object({
      number: z
        .string()
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input:must-be-phone-number', {
            field: ManagingCompanyi18nFields.phoneNumber,
            formats: '+11231231234, 1231231234',
          }),
        })
        .or(z.literal('').nullable().optional()),
      extension: z
        .string()
        .nullable()
        .optional()
        .refine(
          (value) => {
            return (value?.trim().length ?? 0) > 0 && typeof value === 'string'
              ? isInteger(parseInt(value?.trim()))
              : true
          },
          {
            message: t('invalid-extension-number'),
          },
        ),
    })
    .optional()
    .nullable(),
  emergencyPhone: z
    .object({
      number: z
        .string()
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input:must-be-phone-number', {
            field: ManagingCompanyi18nFields.emergencyPhone,
            formats: '+11231231234, 1231231234',
          }),
        })
        .or(z.literal('').nullable().optional()),
      extension: z
        .string()
        .nullable()
        .optional()
        .refine(
          (value) => {
            return (value?.trim().length ?? 0) > 0 && typeof value === 'string'
              ? isInteger(parseInt(value?.trim()))
              : true
          },
          {
            message: t('invalid-extension-number'),
          },
        ),
    })
    .optional()
    .nullable(),
  logo: z.any().optional().nullable(),
  logoAvatar: z.any().optional().nullable(),
  address: z
    .object({
      address1: z.string().optional().nullable(),
      address2: z.string().optional().nullable(),
      apartmentNumber: z.string().optional().nullable(),
      city: z.string().optional().nullable(),
      zip: z.string().optional().nullable(),
      state: z.string().optional().nullable(),
      country: z.string().optional().nullable(),
      countryCode: z.string().optional().nullable(),
      provinceCode: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
})

export type ManagingCompany = z.infer<typeof managingCompanySchema>
