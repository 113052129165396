import { Colors } from '@walter/shared'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { t } from '../utils'
import { GlobalLayout } from './GlobalLayout'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Colors.white};
  display: flex;
`

const Content = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  padding: 5vw;
  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
    `}
`

const Sidebar = styled.div`
  width: 55%;
  background-color: ${Colors.black};
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  background-image: linear-gradient(0deg, rgba(31, 31, 108, 0.65), rgba(31, 31, 108, 0.65)),
    url('https://storage.googleapis.com/upload-mtl/walter/static/login-usewalter.jpg');
`

const Inner = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  ${(props) =>
    props.isMobile &&
    css`
      max-width: 100%;
    `}
`

type AuthContentType = {
  children: React.ReactNode
}

export const AuthContent = ({ children }: AuthContentType) => {
  return (
    <Container>
      <div className="absolute top top-8 left-8 flex gap-5 items-end">
        <img
          src="https://storage.googleapis.com/upload-mtl/logos/usewalter-stacked-logo_h40px.png"
          alt="Logo UseWalter"
        />
        <p className="-mb-1.5 text-foreground font-bold">{t('by')}</p>
        <img
          className="-mb-2 h-[50px]"
          src="https://storage.googleapis.com/upload-mtl/walter/static/hopem-logo_h52px.png"
          alt="Logo Hopem"
        />
      </div>
      <Content isMobile={isMobile}>
        <Inner isMobile={isMobile}>{children}</Inner>
      </Content>
      {!isMobile && <Sidebar />}
    </Container>
  )
}

export const AuthLayout = ({ children }: { children: React.ReactNode }) => (
  <GlobalLayout>
    <AuthContent>{children}</AuthContent>
  </GlobalLayout>
)
