import { AnimationsUtils, Spacing } from '@walter/shared'
import { PopoverContext, PopoverAction } from '@walter/shared-web'
import { stripUnit } from 'polished'
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { Portal } from 'react-portal'
import styled, { css } from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
`

const Overlay = styled.div<{
  offset: { top: number; left: number }
  position: string
  triggerDimensions: { width: number; height: number }
  alignment: string
}>`
  position: fixed;
  top: ${(props) => props.offset.top}px;
  left: ${(props) => props.offset.left}px;
  z-index: 700;
  ${(props) =>
    props.alignment === 'left' &&
    css`
      left: ${props.offset.left}px;
    `}
  ${(props) =>
    props.position === 'top' &&
    css`
      transform: translateY(-100%);
      margin-top: -${props.triggerDimensions.height}px;
    `}
`

const Content = styled.div<{
  alignment: PopoverAlignment
  position: PopoverPosition
  triggerDimensions: TriggerDimensions
}>`
  position: relative;
  margin-top: ${Spacing.tiny};
  top: ${(props) => props.triggerDimensions.height}px;
  ${(props) =>
    props.alignment === 'right' &&
    css`
      transform: translateX(-100%);
      margin-left: ${props.triggerDimensions.width}px;
    `}
  ${(props) =>
    props.alignment === 'center' &&
    css`
      transform: translateX(-50%);
      margin-left: ${(stripUnit(props.triggerDimensions.width) as number) * 0.5}px;
    `}
  ${(props) =>
    props.position === 'top' &&
    css`
      margin-top: 0;
      margin-bottom: ${Spacing.tiny};
    `}
`

type TriggerDimensions = { width: number; height: number }

export enum PopoverAlignment {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
}

export enum PopoverPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

type PopoverProps = {
  dataTestId?: string
  trigger: ForwardRefRenderFunction<
    HTMLElement,
    {
      onClick: (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => void
    }
  >
  children: React.ReactNode
  actions?: PopoverAction[]
  alignment?: PopoverAlignment
  position?: PopoverPosition
  hideOnClick?: boolean
  testID?: string
}

export const Popover = ({
  dataTestId,
  trigger,
  children,
  alignment = PopoverAlignment.LEFT,
  position = PopoverPosition.BOTTOM,
  hideOnClick = true,
  testID,
}: PopoverProps) => {
  const { popoverVisible: popoverContentVisible, setPopoverVisible: setPopoverContextVisible } =
    React.useContext(PopoverContext)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [popoverOffset, setPopoverOffset] = useState({ top: 0, left: 0 })
  const [triggerDimensions, setTriggerDimensions] = useState<TriggerDimensions>({ width: 0, height: 0 })
  const [scrollParent, setScrollParent] = useState<HTMLElement | null>()
  const triggerEl = useRef<any>()
  const popoverRef = useRef<any>()
  const Trigger = forwardRef(trigger)

  useEffect(() => {
    if (!popoverContentVisible) {
      hidePopover()
    }
  }, [popoverContentVisible])

  useEffect(() => {
    setPopoverContextVisible(popoverVisible)
  }, [popoverVisible])

  const showPopover = () => {
    if (triggerEl) {
      setPopoverOffset({
        top: triggerEl.current.getBoundingClientRect().top,
        left: triggerEl.current.getBoundingClientRect().left,
      })
      setTriggerDimensions({
        width: triggerEl.current.offsetWidth,
        height: triggerEl.current.offsetHeight,
      })
    }

    setPopoverVisible(true)

    // TO-DO
    // Hide popover on scroll
  }

  const hidePopoverOnCLick = (e: MouseEvent) => {
    const clickIsIndside = popoverRef.current?.contains(e.target)

    if (!clickIsIndside && hideOnClick) {
      setPopoverVisible(false)
    }
  }

  const hidePopover = () => {
    setPopoverVisible(false)
  }

  useEffect(() => {
    setScrollParent(AnimationsUtils.getScrollParent(triggerEl.current))
  }, [])

  useEffect(() => {
    if (popoverVisible) {
      document.addEventListener('click', hidePopoverOnCLick)

      if (scrollParent) {
        scrollParent.addEventListener('scroll', hidePopover)
      }
    } else {
      document.removeEventListener('click', hidePopoverOnCLick)

      if (scrollParent) {
        scrollParent.removeEventListener('scroll', hidePopover)
      }
    }

    return () => {
      if (scrollParent) {
        scrollParent.removeEventListener('scroll', hidePopover)
      }
      document.removeEventListener('click', hidePopoverOnCLick)
    }
  }, [scrollParent, popoverVisible])

  return (
    <Container data-test-id={dataTestId} data-cy={testID}>
      <Trigger
        onClick={(e) => {
          e?.stopPropagation && e.stopPropagation()
          e?.preventDefault && e.preventDefault()
          if (popoverVisible) hidePopover()
          else showPopover()
        }}
        ref={triggerEl}
      />
      {popoverVisible && (
        <Portal>
          <Overlay
            triggerDimensions={triggerDimensions}
            offset={popoverOffset}
            alignment={alignment}
            position={position}
          >
            <Content
              data-test-id={dataTestId + '_Content'}
              triggerDimensions={triggerDimensions}
              alignment={alignment}
              position={position}
              ref={popoverRef}
            >
              {children}
            </Content>
          </Overlay>
        </Portal>
      )}
    </Container>
  )
}
