import { rgba } from 'polished'

// Greys.
export const greyDark = '#2A3242'
export const grey = '#58626D'
export const greyLight = '#9DA6B0'
export const greyLightest = '#E0E3E7'
export const offWhite = '#F3F4F7'
export const greyBorder = '#CED2D6'

// Misc
export const white = '#fff'
export const black = '#000'
export const lightRed = '#ff7d7d'
export const lightestRed = '#ffeded'
export const lightYellow = '#ffff7d'
export const lightestYellow = '#FFFFF2'
export const lightGreen = '#99ff7d'
export const lightestGreen = '#f3fff0'
export const gold = '#B8A376'
export const lightblue = '#EBEFF3'
export const coral = '#F3785D'
export const tint = '#2f95dc'

// Section colors
export const emerald = '#208288'
export const green = '#66BB6A'
export const red = '#F36F5D'
export const yellow = '#F9AF09'
export const orange = '#F58614'
export const blue = '#449FE8'
export const indigo = '#495ED0'
export const purple = '#AB47BC'
export const lightPurple = '#AB47BC33'

// Other
export const headspaceOrange = '#FF5F00'
export const blueMessenger = '#0078FF'
export const imageOverlay = rgba(black, 0.6)

// Typographie
export const textDark = greyDark
export const textLight = rgba(greyDark, 0.7)

// Set colors
export const primaryColor = gold
export const secondaryColor = emerald
export const borderColor = greyLightest
export const borderColorForm = greyBorder
export const warning = yellow
export const rejectedReservationEventColor = red
export const confirmedReservationEventColor = green
export const pendingReservationEventColor = yellow
export const privateEventColor = blue
export const publicEventColor = grey
export const communityEventColor = gold

// Module Colors
export const news = yellow
export const chat = primaryColor
export const tasks = green
export const bookings = blue
export const events = blue
export const packages = orange
export const managerStore = purple
export const marketplace = indigo
export const violations = red // Module not implemented yet
export const payments = red // Temporay
