import { z } from 'zod'
import { i18n, t } from '../../utils'

export const ThirdPartySettingsHopemi18nFields = {
  importEnabled: t('third-party:import-enabled'),
  hopemCompanyId: t('primmo-company-id'),
  hopemBuildingId: t('primmo-building-id'),
  automaticallyInviteResidentCreatedFromThirdParty: t('automatically-invite-new-resident-created-from-third-party'),
}

export const ThirdPartySettingsHapiHopemi18nFields = {
  importEnabled: t('third-party:import-enabled'),
  hopemCompanyId: t('primmo-company-id'),
  hopemBuildingId: t('primmo-building-id'),
  hopemCompanyDivisionId: t('primmo-company-division-id'),
  automaticallyInviteResidentCreatedFromThirdParty: t('automatically-invite-new-resident-created-from-third-party'),
}

export const ThirdPartySettingsHapiHopemSchema = z.object({
  importEnabled: z.boolean(),
  config: z.object({
    hopemCompanyId: z.number().or(
      z
        .string({
          required_error: i18n.t('input:is-required', { field: ThirdPartySettingsHapiHopemi18nFields.hopemCompanyId }),
        })
        .min(1, {
          message: i18n.t('input:is-required', { field: ThirdPartySettingsHapiHopemi18nFields.hopemCompanyId }),
        })
        .transform((val) => (isNaN(Number(val)) ? 0 : Number(val))),
    ),
    hopemBuildingId: z.number().or(
      z
        .string({
          required_error: i18n.t('input:is-required', { field: ThirdPartySettingsHapiHopemi18nFields.hopemBuildingId }),
        })
        .min(1, {
          message: i18n.t('input:is-required', { field: ThirdPartySettingsHapiHopemi18nFields.hopemCompanyId }),
        })
        .transform((val) => (isNaN(Number(val)) ? 0 : Number(val))),
    ),
    hopemCompanyDivisionId: z.number().or(
      z
        .string({
          required_error: i18n.t('input:is-required', {
            field: ThirdPartySettingsHapiHopemi18nFields.hopemCompanyDivisionId,
          }),
        })
        .min(1, {
          message: i18n.t('input:is-required', { field: ThirdPartySettingsHapiHopemi18nFields.hopemCompanyDivisionId }),
        })
        .transform((val) => (isNaN(Number(val)) ? 0 : Number(val))),
    ),
    automaticallyInviteResidentCreatedFromThirdParty: z.boolean(),
  }),
})

export type ThirdPartySettingsHapiHopem = z.infer<typeof ThirdPartySettingsHapiHopemSchema>

export const ThirdPartySettingsHopemSchema = z.object({
  importEnabled: z.boolean(),
  config: z.object({
    hopemCompanyId: z.number().or(
      z
        .string({
          required_error: i18n.t('input:is-required', { field: ThirdPartySettingsHopemi18nFields.hopemCompanyId }),
        })
        .min(1, { message: i18n.t('input:is-required', { field: ThirdPartySettingsHopemi18nFields.hopemCompanyId }) })
        .transform((val) => (isNaN(Number(val)) ? 0 : Number(val))),
    ),
    hopemBuildingId: z.number().or(
      z
        .string({
          required_error: i18n.t('input:is-required', { field: ThirdPartySettingsHopemi18nFields.hopemBuildingId }),
        })
        .min(1, { message: i18n.t('input:is-required', { field: ThirdPartySettingsHopemi18nFields.hopemCompanyId }) })
        .transform((val) => (isNaN(Number(val)) ? 0 : Number(val))),
    ),
    automaticallyInviteResidentCreatedFromThirdParty: z.boolean(),
  }),
})

export type ThirdPartySettingsHopem = z.infer<typeof ThirdPartySettingsHopemSchema>

export const ThirdPartySettingsCondoManageri18nFields = {
  importEnabled: t('import-enabled'),
  condoManagerId: t('add-your-condo-manager-id-to-automatically-integrate-with-walter'),
  automaticallyInviteResidentCreatedFromThirdParty: t('automatically-invite-new-resident-created-from-third-party'),
}

export const ThirdPartySettingsCondoManagerSchema = z.object({
  importEnabled: z.boolean(),
  config: z.object({
    condoManagerId: z.string(),
    automaticallyInviteResidentCreatedFromThirdParty: z.boolean(),
  }),
})

export type ThirdPartySettingsCondoManager = z.infer<typeof ThirdPartySettingsCondoManagerSchema>
