import { Colors, Spacing } from '@walter/shared'
import {
  IconButton,
  NavBuilding,
  Popover,
  PopoverContext,
  PopoverPosition,
  t,
  useIsFirstRender,
} from '@walter/shared-web'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import { stripUnit } from 'polished'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AppContext from '../../../contexts/App'
import BuildingNav from '../BuildingNav'
import { MENUS } from '../Menu'
import Menu from './Menu'

const Container = styled.div`
  display: flex;
`

const Divider = styled.div`
  width: 1px;
  height: ${Spacing.xLarge};
  background-color: ${Colors.borderColor};
  margin: 0 ${Number(stripUnit(Spacing.small)) * 1.5 + 'px'};
`

const List = styled.div`
  display: flex;
  > * {
    margin-right: ${Spacing.small};
  }
`

const More = styled.div`
  display: flex;
  position: relative;
`

export default function ProjectSelector() {
  const navigate = useNavigate()
  const { projectId: projectIdParam = '' } = useParams<{ projectId: string }>()
  const projectId = projectIdParam === 'all' ? null : projectIdParam
  const { currentManagingCompanyProjects, isAllProjects, moduleSlug } = useContext(AppContext)
  const { setPopoverVisible: setPopoverContentVisible } = useContext(PopoverContext)
  const [projects, setProjects] = useState(orderBy(currentManagingCompanyProjects, ['name']))
  const isFirstRender = useIsFirstRender()

  const putSelectedProjectFirst = (projectId: string) => {
    const workingProjects = projects
    const selectedProject = projects.find((project) => project?.id === projectId)
    const selectedProjectIndex = projects.findIndex((project) => project?.id === projectId)

    workingProjects.splice(selectedProjectIndex, 1)
    if (selectedProject) {
      setProjects([selectedProject, ...workingProjects])
    }
  }

  useEffect(() => {
    if (projectId && !isFirstRender) {
      orderProjects(projectId)
    }
  }, [projectId, currentManagingCompanyProjects])

  const orderProjects = (id: string) => {
    const projectIdInFirst6 = take(projects, 6).map((project) => project?.id)
    if (!projectIdInFirst6.includes(id) && id) {
      putSelectedProjectFirst(id)
    } else {
      setProjects(currentManagingCompanyProjects)
    }
  }

  const handleProjectClick = (id: string) => {
    setPopoverContentVisible(false)
    if (!moduleSlug) {
      navigate(`/${id}`)
    } else {
      navigate(`/${id}/${moduleSlug}`)
    }
  }

  function handleClickAllProjects() {
    const menu = MENUS.find(({ slug }) => slug === moduleSlug)
    if (!moduleSlug || !menu?.availableInAllProjects) {
      navigate(`/`)
    } else {
      navigate(`/all/${moduleSlug}`)
    }
  }

  return (
    <Container data-test-id="ProjectSelector_Container">
      <NavBuilding
        dataTestId="AllProjects"
        slug="all"
        name={t('all-projects')}
        placeholder
        handleClick={handleClickAllProjects}
        isActive={isAllProjects}
      />
      <Divider />

      <List data-test-id="Projects_List">
        {take(projects, 6).map((project) => {
          if (!project) {
            return null
          }
          return <BuildingNav currentProjectIdPathname={projectId} key={project?.id} project={project} />
        })}
      </List>

      <More data-test-id="More_Button">
        <Popover
          dataTestId="More_Popover"
          trigger={({ onClick }, forwardRef) => (
            <IconButton
              size="small"
              icon="disclosure"
              data-tip={t('view-more-projects')}
              ref={forwardRef as React.Ref<HTMLButtonElement>}
              onClick={onClick}
            />
          )}
          position={PopoverPosition.BOTTOM}
        >
          <Menu projects={projects} onProjectClick={handleProjectClick} />
        </Popover>
      </More>
    </Container>
  )
}
